<script setup lang="ts">
import { Dayjs } from "dayjs";

import type { Promotion } from "@/types";

type PromotionType = Promotion["type"];

const { t } = useT();
const { isDesktop } = useDevice();
const { isEventTheme } = useEventsCollector();

const props = withDefaults(
	defineProps<{
		showAvailable?: boolean;
		enabled?: boolean;
		image: string;
		subTitle: string;
		title: string;
		coins: number;
		entries: number;
		date: string | number | Dayjs;
		type?: PromotionType;
		bestDeal: boolean;
		mostPopular: boolean;
		badge?: Record<string | number, string | number | boolean | null | undefined>;
		currencySymbol?: string;
		money?: string;
		textTooltip?: string;
		magicBoxDate?: string;
		magicBoxAvailable?: boolean;
		questSubscribed?: boolean;
		promotionInfo?: boolean;
		usualPrice?: string;
		applePayEnabled?: boolean;
		lastPayProvider?: object | null;
		subType?: string | null;
		vipClubEnabled?: boolean;
		vipSpinEnabled?: boolean;
		imgWidth?: number;
		questTitle?: string;
		imgHeight?: number;
		superchargedMode?: boolean;
		withDepositStreak?: boolean;
		regularMode?: boolean;
	}>(),
	{
		imgWidth: 80,
		imgHeight: 100
	}
);
const { isEventBox } = useEventsCollector();

const emit = defineEmits<{ (event: "click"): void; (event: "onCountDownStop"): void }>();
const { start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });
const { hasDeposits, isVerificationCompleted } = useProfileData();
const isAnimated = ref(false);
const stopAnimationState = () => {
	isAnimated.value = false;
};

const handleClickWithDelay = () => {
	emit("click");
};

const handleClick = () => {
	handleStartAnimation();
	handleStartPopper();

	if ((props.applePayEnabled || props.lastPayProvider) && hasDeposits.value && isVerificationCompleted.value) {
		isAnimated.value = true;

		return;
	}

	emit("click");
};

useEvent(["LEAVE_CASH"], () => {
	stopAnimationState();
});

const onCountDownStop = () => {
	if (props.enabled && props.showAvailable) {
		emit("onCountDownStop");
	}
};

const { durationLeft: promotionTime, reset } = useCountdown({
	timestamp: props.date,
	formatToken: "H[:]mm[:]ss",
	onCountdownStop: onCountDownStop
});

watch(
	() => props.date,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);

const isEvent = computed(() => isEventTheme.value && (props.bestDeal || props.mostPopular));
</script>

<template>
	<AAnimationTranslateFlip
		:isDesktop="isDesktop"
		:animationState="isAnimated"
		@handle-click-with-delay="handleClickWithDelay"
	>
		<ACard
			:imgAttrs="{
				width: imgWidth,
				height: imgHeight,
				src: image
			}"
			width="328px"
			height="246px"
			:class="[
				`card-${type}`,
				{
					'card-disabled':
						(!enabled && type !== 'quest' && type !== 'invite') || (!magicBoxAvailable && type === 'magicBox')
				},
				{ 'card-popular': mostPopular },
				{ 'card-best': bestDeal },
				{ 'card-sub-type': subType },
				{ 'card-supercharged': superchargedMode && !isEventBox }
			]"
			data-tid="card-img"
			@click="handleClick"
		>
			<template v-if="!enabled && !['quest', 'invite', 'inviteQualification'].includes(type || '')" #default>
				<div class="lock">
					<ASvg name="80/lock" class="icon" />
					<AText variant="tanzay" class="text-tlalnepantla" :modifiers="['semibold']">
						{{ textTooltip }}
					</AText>
				</div>
			</template>
			<template #top>
				<div class="badge-wrapper">
					<ABadge v-if="bestDeal || mostPopular" background="var(--goeteborg)" variant="info" autosize>
						<AText variant="ternopil" class="text-carabanchel badge" :modifiers="['bold', 'uppercase']">
							{{ bestDeal ? t("Best deal") : t("Most popular") }}
							<template v-if="badge?.percent && badge?.text"> - {{ badge?.percent }} {{ badge?.text }}</template>
						</AText>
					</ABadge>
					<ABadge v-if="!bestDeal && !mostPopular && subType" :background="'var(--goerlitz)'" variant="info" autosize>
						<AText class="text-coro badge" variant="ternopil" :modifiers="['semibold', 'uppercase']">
							<span>{{ subType }}</span>
							<template v-if="badge?.percent && badge?.text"> - {{ badge?.percent }} {{ badge?.text }}</template>
						</AText>
					</ABadge>
					<ABadge v-if="type === 'quest'" background="var(--goeteborg)" variant="info" autosize>
						<AText variant="ternopil" class="text-carabanchel badge" :modifiers="['bold', 'uppercase']">
							{{ t("Complete & win real prizes") }}
						</AText>
					</ABadge>
				</div>

				<NuxtImg
					v-if="type === 'magicBox' && (superchargedMode || regularMode)"
					:src="`/nuxt-img/mystery-box/label-${regularMode ? 'regular' : 'super'}.png`"
					:class="['super-box', { regular: regularMode }]"
					:width="regularMode ? '286' : '414'"
					height="114"
					:alt="`label ${regularMode ? 'regular' : 'super'} box`"
					format="avif"
					loading="lazy"
				/>

				<div
					v-if="['quest', 'magicBox', 'rankLeague', 'rankLeagueReward'].includes(type as keyof PromotionType)"
					class="free"
				>
					<ASvg name="48/free" class="icon" />

					<AText
						variant="tempe"
						class="text-tlalnepantla free-text text-carabanchel"
						:modifiers="['semibold', 'uppercase']"
					>
						{{ t("Free") }}
					</AText>
				</div>
				<NuxtImg
					v-if="isEvent"
					src="/nuxt-img/event-decor/black-friday-sale.svg"
					class="black-friday-sale"
					width="440"
					height="440"
					alt="black friday sale icon"
					loading="lazy"
				/>

				<AText class="title" variant="topeka" :modifiers="['bold', 'uppercase']">{{ title }}</AText>

				<AText v-if="questTitle" class="sub-title" :modifiers="['extrabold']">
					{{ questTitle }}
				</AText>
				<AText v-else class="sub-title" variant="topeka" :modifiers="['semibold']">
					{{ subTitle }}
				</AText>

				<AText v-if="showAvailable && enabled" variant="topeka" :modifiers="['semibold']" class="label-time">
					{{ t("Ends in:") }}
					<AText :modifiers="['extrabold']" class="label-time" gradient="var(--goeteborg)">
						<span>{{ promotionTime }}</span>
					</AText>
				</AText>
			</template>
			<template #bottom>
				<div class="box">
					<AText v-if="coins" variant="tanzay" :modifiers="['semibold', 'uppercase']" class="count text-tlalnepantla">
						<AText
							v-if="type === 'quest'"
							variant="ternopil"
							class="count-before"
							:modifiers="['semibold', 'uppercase']"
						>
							{{ t("Prize of the day") }}
						</AText>
						<NuxtImg
							src="/nuxt-img/prizes/funcoins.png"
							class="icon-fun"
							width="56"
							height="56"
							alt="Funcoins prize"
							format="avif"
						/>
						{{ numberFormat(coins) }}
					</AText>

					<AText v-if="entries" variant="tanzay" :modifiers="['semibold', 'uppercase']" class="count text-tlalnepantla">
						<AText
							v-if="type === 'quest'"
							variant="ternopil"
							class="count-before"
							:modifiers="['semibold', 'uppercase']"
						>
							{{ t("Prize of the day") }}
						</AText>
						<NuxtImg
							src="/nuxt-img/prizes/citycoins.png"
							class="icon-city"
							width="56"
							height="56"
							alt="Citycoins prize"
							format="avif"
						/>
						<template v-if="!promotionInfo || type === 'magicBox' || type === 'verification' || type === 'rankLeague'">
							{{ numberFormat(entries) }}
							<AText v-if="type !== 'invite'" variant="ternopil" class="count-after" :modifiers="['bold']">
								{{ t("Free City Coins") }}
							</AText>
						</template>
						<template v-else>
							{{ numberFormat(entries) }}
						</template>
					</AText>

					<AButton v-if="type === 'emailLottery'" variant="primary" class="promo" data-tid="promo-get-email">
						<AText :modifiers="['bold', 'uppercase']">
							{{ t("Get email") }}
						</AText>
					</AButton>
					<template v-if="type === 'verification'">
						<AButton variant="primary" class="promo" size="s" data-tid="promo-verification">
							<AText :modifiers="['semibold']">
								{{ t("Secure now") }}
							</AText>
						</AButton>
					</template>

					<template v-if="type !== 'verification'">
						<AButton v-if="type === 'magicBox'" variant="primary" size="s" class="promo" data-tid="promo-magic-box">
							<AText :modifiers="['semibold', 'capitalize']" class="text-tlalnepantla">
								{{ superchargedMode || regularMode ? t("Try now for free") : t("Open Now") }}
							</AText>
						</AButton>
						<AButton
							v-else-if="type === 'rankLeague'"
							variant="primary"
							size="s"
							class="promo"
							data-tid="promo-rank_league"
						>
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ vipClubEnabled ? t("Find Out More") : t("Activate") }}
							</AText>
						</AButton>
						<AButton
							v-else-if="type === 'rankLeagueReward'"
							variant="primary"
							size="s"
							class="promo"
							data-tid="promo-rank-league-reward"
						>
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ vipSpinEnabled ? t("Spin the Wheel") : t("Play On & Unlock") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'invite'" variant="primary" size="s" class="promo" data-tid="promo-invite">
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ t("Copy Link") }}
							</AText>
						</AButton>
						<AButton
							v-else-if="type === 'inviteQualification'"
							variant="primary"
							size="s"
							class="promo"
							data-tid="promo-invite-qa"
						>
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ t("Qualify") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'quest'" variant="primary" size="s" class="promo" data-tid="promo-quest">
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ t("Go!") }}
							</AText>
						</AButton>
						<AButton v-else variant="primary" size="s" class="promo" data-tid="promo-buy-now">
							<AText :modifiers="['semibold']" class="text-tlalnepantla">
								{{ t("Buy Now") }} {{ currencySymbol }}{{ money }}
							</AText>
							<NuxtImg
								v-if="withDepositStreak"
								src="/nuxt-img/deposit-streak/box-prize.webp"
								class="deposit-prize-box"
								width="45"
								height="48"
								format="avif"
								alt="deposit-prize-box"
								loading="lazy"
							/>
						</AButton>
					</template>

					<AText v-if="usualPrice" variant="topeka" :modifiers="['semibold']" class="foot">
						<i18n-t keypath="was {key}">
							<template #key>
								{{ currencySymbol }}<s>{{ usualPrice }}</s>
							</template>
						</i18n-t>
					</AText>
				</div>
			</template>
		</ACard>
	</AAnimationTranslateFlip>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: var(--graz);

	&:deep(> img) {
		margin: auto;
	}

	.badge-wrapper {
		min-height: 22px;
	}

	.badge {
		padding-right: gutter(0.5);
		padding-left: gutter(0.5);
		box-shadow: none;
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 gutter(0.4) gutter(2) 0;
		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
			transform: translateX(-1px);
		}
	}

	.black-friday-sale {
		position: absolute;
		right: 0;
		top: 0;
		width: 113px;
		height: 60px;
	}

	.super-box {
		position: absolute;
		left: 12px;
		top: -16px;
		width: 138px;
		height: 38px;
		transform: rotate(10deg);

		&.regular {
			width: 92px;
		}
	}

	&.card-offerDeposit,
	&.card-offerDate {
		background: var(--ghent);
	}
	&.card-verification {
		background: var(--guiren);
	}
	&.card-popular {
		background: var(--guliston);
	}
	&.card-invite,
	&.card-inviteQualification {
		background: var(--goiania);
	}
	&.card-quest {
		background: var(--gadwal);
	}
	&.card-magicBox {
		background: var(--goerlitz);
		overflow: visible;

		&.card-supercharged {
			background: var(--gosaba);
		}
	}
	&.card-sub-type {
		background: var(--guiren);
	}
	&.card-offerBirthday {
		background: var(--gonko);
	}
	&.card-best {
		background: var(--guaruja);
	}
	&.card-rankLeagueReward,
	&.card-rankLeague {
		background: var(--greenburgh);
	}

	.lock {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 gutter(4);
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 10, 63, 0.35);
		backdrop-filter: blur(6px);

		.icon {
			font-size: 90px;
		}
	}

	.title {
		padding: gutter(0.25) 0;
	}

	.disabled {
		gap: gutter(1);
		box-shadow: 0px 4px 10px 0px var(--chile);
		opacity: 1 !important;

		.icon {
			font-size: 24px;
		}
	}
}

.count {
	display: flex;
	align-items: center;
	line-height: 1;
	gap: gutter(0.5);
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	&.single {
		margin-bottom: gutter(5.5);
	}
}

.count-before {
	text-align: left;
	max-width: 52px;
	font-family: var(--font-family);
}

.count-after {
	text-align: left;
	max-width: 60px;
	font-family: var(--font-family);
}

.box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 100%;
	height: 100%;
	gap: gutter(1);

	button {
		min-width: 140px;
		width: max-content;
	}

	.icon-fun,
	.icon-city {
		width: 22px;
		height: 22px;
	}

	.icon-fun {
		filter: drop-shadow(0 0 6px var(--cheremushki));
	}

	.icon-city {
		filter: drop-shadow(0 0 6px var(--сirebon));
	}
}

.foot {
	position: absolute;
	bottom: 15px;
	left: 55px;
	text-align: left;
}

.sub-title {
	text-shadow: 0 1px 1px var(--chile);
}

.deposit-prize-box {
	width: 16px;
	height: 18px;
	object-fit: cover;
	margin-left: 4px;
}

.info-txt {
	max-width: 180px;
	margin: gutter(1) auto 0;
}
</style>
